// 进度：点击缩略图时，滚动到选中的作品高度
//导入CSS
import './style.css';
import gsap from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
// 导入同步运行时库
// import 'regenerator-runtime/runtime';
// 测试工具
// import * as dat from 'dat.gui';

// 初始化插件
gsap.registerPlugin(ScrollToPlugin);

//-- 公共变量 --//
//全局设置变量
var client = '',
width = window.innerWidth,
height = window.innerHeight,
aspect = width / height,
// 全局设置
settings = {
  // 音乐播放状态
  musicPlayed: false,
  // 导航按钮是否按下(1)
  navigationOpened: false,
  // 作品类别是否打开(2)
  categoryOpened: false,
  // 作品列表是否打开(3)
  worksListOpened: false,
  // 当前作品类型
  category: 'vi',
  // 作品是否打开(4)
  worksDetailOpened: false,
  // 当前选中的作品名称
  worksName: 'a',
  // 大幅作品是否打开
  largeWorksOpened: false,
  // 关于我们是否打开
  aboutOpened: false,
  // 作品详细描述是否打开(large)
  largeWorksDetailsOpened: false,
  // 离左边的距离
  moveToLeft: 0,
  // 离左边最大距离
  moveLeftMax: 60,
  // 当前第几幅
  worksImageNow: 0,
  // 作品总数量
  worksImageTotal: 0,
  // 开启debug模式
  debug: false,
},
// 网页元素dom的集合
dom = {
  n1: document.getElementById('navigationContent1'),
  n2: document.getElementById('navigationContent2'),
  n3: document.getElementById('navigationContent3'),
  n4: document.getElementById('navigationContent4'),
  c1: document.getElementById('closeNavigation1'),
  c2: document.getElementById('closeNavigation2'),
  c3: document.getElementById('closeNavigation3'),
  c4: document.getElementById('closeNavigation4'),
  lw: document.getElementById('largeWorks'),
  lwi: document.getElementById('largeWorksImages'),
  swi: document.getElementById('smallWorksImages'),
  point: document.getElementById('thePoint'),
},
// 网页元素dom的设置参数
domSettings = {
  n1HalfWidth: 7.14,
  n1Width: 14.28,
  n2Width: 21.42,
  n3Width: 28.56,
  n4Width: 35.74,
  large_scrollTop: 0,
},
// 延迟移动的变量
delayedMove,
// 测试对象
gui;

//-- 确认平台 --//
if(/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
  client = 'mobile';
  document.body.classList.add('displayOverFlowY');
  document.getElementById('grain').classList.add('grainInMobile');
  document.getElementById('largeWorksImages').style.cssText = 'overflow-y: scroll';
}else{
  client = 'pc';
  document.body.classList.add('hiddenOverFlowY');
  document.body.classList.add('useGPU');
  document.getElementById('grain').classList.add('grainInPC');
}

init();
//-- 初始化页面内容 --//
function init() {
  inputEvents();
  resize();
  // console.log('test %cmode', 'color: blue');

  // 先滚动一遍，消除大幅作品页的LOGO作品列表的跳帧
  dom.lw.style.cssText = 'display: block; opacity: 0.005;';
  gsap.to(dom.lwi, {
    scrollTo: document.querySelector('#largeWorksImages .sezeArtLabLogo').scrollHeight,
    duration: 3,
    onComplete: () => {
      dom.lw.style.cssText = '';
      dom.lw.classList.remove('largeWorksInit');
    }
  });


  document.getElementById('playAndEnter').addEventListener('click', openPage.bind('', 'play'));
  document.getElementById('stopAndEnter').addEventListener('click', openPage.bind('', 'pause'));

  // worker子线程功能
  // var workers = new Worker('./src/js/worker.js');
  // workers.postMessage(2);
  // workers.onmessage = (e) => {
    // console.log(e.data)
  // }
}



function openPage(musicState) {
  // 黑幕中的元素淡出
  document.getElementById('blackCurtainLogo').classList.add('noDisplayAnimation');
  document.getElementById('blackCurtainText').classList.add('noDisplayAnimation');
  document.getElementById('enterButton').classList.add('noDisplayAnimation');
  // 黑幕上移到屏幕外
  document.getElementById('blackCurtain').classList.add('blackCurtainAnimation');

  // 给页脚的线添加动画
  document.getElementById('footer').style.zIndex = 4;
  document.getElementById('footerParagraphLine1').classList.add('footerParagraphLine1');
  document.getElementById('footerParagraphLine2').classList.add('footerParagraphLine2');
  document.getElementById('footerParagraphLine3').classList.add('footerParagraphLine3');
  document.getElementById('footerParagraphLine4').classList.add('footerParagraphLine4');
  document.getElementById('footerParagraphLine5').classList.add('footerParagraphLine5');

  document.getElementById('footerInfoLine1').classList.add('footerInfoLine1');
  document.getElementById('footerInfoLine2').classList.add('footerInfoLine2');
  document.getElementById('footerInfoLine3').classList.add('footerInfoLine3');
  document.getElementById('footerInfoLine4').classList.add('footerInfoLine4');
  document.getElementById('footerInfoLine5').classList.add('footerInfoLine5');
  var footerTimeOut = setTimeout(() => {
    document.getElementById('footer').style.cssText = '';
    clearTimeout(footerTimeOut);
  }, 2000);
  // 给页脚的文字添加动画
  var footerP = document.querySelectorAll('.footerPIn')
  for(var i = 0; i < footerP.length; i++) {
    footerP[i].classList.add('footerP');
  }

  // 播放音乐
  if(musicState == 'play') {
    settings.musicPlayed = true;
    musicController('play');
  }

  // 获取音乐频谱--本功能要在一开始的时候就创建，否则不能用
  var audioElement = document.getElementById('indexAudio');
  // 设置允许跨域，audioContext连接对跨域有限制
  // audioElement.crossOrigin = "anonymous"
  // 创建音频语境
  var audioCtx = new (window.AudioContext || window.webkitAudioContext)();
  // 创建音频频谱
  var analyser = audioCtx.createAnalyser();
  // 得到音源（目前问题出在这里，没有获取到音源）
  var source = audioCtx.createMediaElementSource(audioElement);
  // var source = new MediaElementAudioSourceNode(audioCtx, {mediaElement: audioElement,});
  // 将音源连接到频谱
  source.connect(analyser);
  // 频谱连接到扬声器
  analyser.connect(audioCtx.destination);
  // 设置获取的频谱范围
  analyser.fftSize = 32;
  // 获取频谱长度
  var bufferLength = analyser.frequencyBinCount;
  // 获取频谱数组
  var dataArray = new Uint8Array(bufferLength);
  analyser.getByteFrequencyData(dataArray);
  function draw() {
    requestAnimationFrame(draw);
    if(settings.musicPlayed == true) {
      analyser.getByteFrequencyData(dataArray);
    
      document.getElementById('musicLine1').classList.remove('musicLine1');
      document.getElementById('musicLine2').classList.remove('musicLine2');
      document.getElementById('musicLine3').classList.remove('musicLine3');
      document.getElementById('musicLine4').classList.remove('musicLine4');
      document.getElementById('musicLine5').classList.remove('musicLine5');

      document.getElementById('musicLine1').style.height = dataArray[4] / 2 + '%';
      document.getElementById('musicLine2').style.height = dataArray[3] / 2 + '%';
      document.getElementById('musicLine3').style.height = dataArray[2] / 2 + '%';
      document.getElementById('musicLine4').style.height = dataArray[1] / 2 + '%';
      document.getElementById('musicLine5').style.height = dataArray[0] / 2 + '%';
    }

    // if(settings.largeWorksDetailsOpened) {
    //   dom.lwi.scrollTop = document.querySelector('#largeWorksImages .' + settings.worksName).scrollHeight;
    // }
  }
  draw();
}



//-- 交互输入事件 --//
function inputEvents() {
  window.addEventListener("resize", () => {
    resize();
  });
  window.addEventListener("pointerdown", (event) => {
    // pointerDown(event);
  });
  window.addEventListener("touchstart", (event) => {
    
  });
  window.addEventListener("pointermove", (event) => {
    // pointerMove(event);
  });
  window.addEventListener("touchmove", (event) => {
    
  });
  window.addEventListener("pointerup", (event) => {
    // pointerUp(event);
  });
  window.addEventListener("touchend", (event) => {
    // pointerUp(event);
  });
  // dom.lwi.addEventListener("scroll", (event) => {
  //   event.preventDefault();
  // }, {passive: false});
  dom.lwi.addEventListener("wheel", (event) => {
    event.preventDefault();
  }, {passive: false});
  window.addEventListener("wheel", (event) => {
    // 滚轮速度
    var pointerWheel;
    // 圆点尺寸
    var pointHeight = 0;
    if(width > 2560) {
      pointerWheel = event.deltaY * 2;
      pointHeight = 12;
    }else if(width <= 2560 && width > 1920) {
      pointerWheel = event.deltaY * 1.5;
      pointHeight = 10;
    }else if(width <= 1920) {
      pointerWheel = event.deltaY * 1.25;
      pointHeight = 8;
    }
    // 圆点在移动时的动画效果
    gsap.to(dom.point, {
      height: pointHeight * 1.4 + 'px',
      duration: 0.2,
      onCompvare: () => {
        gsap.to(dom.point, {
          height: pointHeight + 'px',
          duration: 0.2
        });
      }
    });

    domSettings.large_scrollTop = dom.lwi.scrollTop + pointerWheel;
    // 记录大幅作品页的滚动距离，通过滚动条控制
    if(width > 500) {
      // 接近底部时
      if(document.querySelector('#largeWorksImages .' + settings.worksName)) {
        if(document.querySelector('#largeWorksImages .' + settings.worksName).scrollHeight - height - dom.lwi.scrollTop < 50) {
          // 到了底部还向下滚动时隐藏滚动条并开启作品详细描述栏
          if(event.deltaY > 0 && settings.moveToLeft == 0) {
            settings.largeWorksDetailsOpened = true;
            dom.lwi.classList.remove('scrollDisplay');
            dom.lwi.classList.add('scrollHidden');
          }
        }else{
          gsap.to(dom.lwi, {
            scrollTo: domSettings.large_scrollTop,
            duration: 0.5
          });
        }
      }

      // 到了底部但向上滚动时显示滚动条，关闭作品详细描述栏
      if(event.deltaY <= 0 && settings.moveToLeft == 0) {
        settings.largeWorksDetailsOpened = false;
        dom.lwi.classList.remove('scrollHidden');
        dom.lwi.classList.add('scrollDisplay');
        gsap.to(dom.lwi, {
          scrollTo: domSettings.large_scrollTop,
          duration: 0.5
        });
      }

      // 如果作品详细描述栏开启时，向下滚动，详细描述栏向左移动，向上滚动，详细描述栏向右移动
      if(settings.largeWorksDetailsOpened) {
        if(event.deltaY > 0 && settings.moveToLeft < settings.moveLeftMax) {
          settings.moveToLeft += 1;
        }else if(event.deltaY < 0 && settings.moveToLeft > 0) {
          settings.moveToLeft -= 1;
        }
        
        if(width > 1100) {
          // 屏幕宽度大于1100时，作品会根据详细描述栏的出现而缩小
          gsap.to(dom.lwi, {
            width: 100 - settings.moveToLeft + 'vw',
            duration: 0.25,
            ease: 'power0'
          });
          // 详细描述栏根据滚轮调整出现位置
          gsap.to(document.getElementById('largeWorksText'), {
            left: 100 - settings.moveToLeft + 'vw',
            duration: 0.15
          });
        }else{
          // 详细描述栏根据滚轮调整出现位置
          gsap.to(document.getElementById('largeWorksText'), {
            left: '100vw',
            duration: 0.15
          });
        }
      }

      if(width > 1100) {
        // 初始化大图对象
        var largeImages;
        if(document.querySelector('#largeWorksImages .' + settings.worksName)) {
          // 设置大图对象为当前浏览的作品
          largeImages = document.querySelector('#largeWorksImages .' + settings.worksName);
          settings.worksImageTotal = largeImages.children.length - 1;
          moveSideAndPoint();
        }

        // 延迟半秒后将图片列表中的图片居中
        clearTimeout(delayedMove);
        delayedMove = setTimeout(() => {
          // 以下是设置大画幅的自动居中
          gsap.to(dom.lwi, {
            scrollTo: settings.worksImageNow * height,
            duration: 0.5
          });
          // 单独设置中间几幅作品的自动居中
          // 当前作品的高度
          var thisDomHeight;
          if(document.querySelector('#smallWorksImages .' + settings.worksName)) {
            thisDomHeight = document.querySelector('#smallWorksImages .' + settings.worksName).children[settings.worksImageNow].getBoundingClientRect().height;
          }

          // 在当前作品之前的所有作品高度
          var beforeDomHeight = 0;
          for(var i = 0; i <= settings.worksImageNow - 1; i++) {
            beforeDomHeight += document.querySelector('#smallWorksImages .' + settings.worksName).children[i].getBoundingClientRect().height;
          }

          // 计算圆点当前应该所处的滚动高度
          var tempValue_point = beforeDomHeight + thisDomHeight * 0.5 - dom.point.getBoundingClientRect().height * 0.5 + 'px';
          gsap.to(dom.point, {
            top: tempValue_point,
            duration: 0.5
          });
        }, 2000);
      }
    }else{
      dom.lwi.classList.remove('scrollHidden');
      dom.lwi.classList.add('scrollDisplay');
    }
  }, {passive: false, useCapture: false});

  // 切换到其他网页标签时暂停媒体，切回时如果媒体应该播放则播放
  document.addEventListener("visibilitychange", function() {
    if(document.visibilityState === 'visible') {
      if(settings.musicPlayed == true) {
        document.getElementById('indexAudio').play();
      }
    }else{
      document.getElementById('indexAudio').pause();
    }
  });

  // 导航按钮时打开导航栏或者关闭about页面
  document.getElementById('navigationButton').addEventListener('click', openNavigation.bind(''));

  // 播放音乐
  document.getElementById('musicButton').addEventListener('click', musicController.bind(''));

  // 复制邮箱地址
  document.getElementById('footerEmail').addEventListener('click', footerEmail.bind(''));

  // 导航栏1--点击Project时显示导航栏2
  document.getElementById('navigationProjects').addEventListener('click', openCategory.bind(''));
  // 导航栏1--点击About时显示about页面
  document.getElementById('navigationAbout').addEventListener('click', openAbout.bind(''));
  
  // 导航栏2--点击文字时显示导航栏3
  document.getElementById('VI/Experience').addEventListener('pointerenter', openWorksList.bind('', 'enter', 'vi'));
  document.getElementById('VI/Experience').addEventListener('click', openWorksList.bind('', 'click', 'vi'));
  document.getElementById('Web').addEventListener('pointerenter', openWorksList.bind('', 'enter', 'web'));
  document.getElementById('Web').addEventListener('click', openWorksList.bind('', 'click', 'web'));
  document.getElementById('Graphics').addEventListener('pointerenter', openWorksList.bind('', 'enter', 'graphics'));
  document.getElementById('Graphics').addEventListener('click', openWorksList.bind('', 'click', 'graphics'));

  // 导航栏3--进入文字时显示导航栏4
  var worksLists = document.getElementsByClassName('worksList');
  for(var i = 0; i < worksLists.length; i++) {
    worksLists[i].addEventListener('pointerenter', openWorksDetail.bind(
      '',
      'enter',
      document.getElementById(worksLists[i].getAttribute('id') + 'Media').innerHTML,
      worksLists[i].getAttribute('id'),
      document.getElementById(worksLists[i].getAttribute('id') + 'Title').innerHTML,
      document.getElementById(worksLists[i].getAttribute('id') + 'Author').innerHTML,
      document.getElementById(worksLists[i].getAttribute('id') + 'Client').innerHTML,
      document.getElementById(worksLists[i].getAttribute('id') + 'Date').innerHTML,
    ));
    worksLists[i].addEventListener('click', openWorksDetail.bind(
      '',
      'click',
      document.getElementById(worksLists[i].getAttribute('id') + 'Media').innerHTML,
      worksLists[i].getAttribute('id'),
      document.getElementById(worksLists[i].getAttribute('id') + 'Title').innerHTML,
      document.getElementById(worksLists[i].getAttribute('id') + 'Author').innerHTML,
      document.getElementById(worksLists[i].getAttribute('id') + 'Client').innerHTML,
      document.getElementById(worksLists[i].getAttribute('id') + 'Date').innerHTML,
    ));
  }

  // 导航关闭按钮
  dom.c1.addEventListener('click', closeNavigation.bind('', 'n1'));
  dom.c2.addEventListener('click', closeNavigation.bind('', 'n2'));
  dom.c3.addEventListener('click', closeNavigation.bind('', 'n3'));
  dom.c4.addEventListener('click', closeNavigation.bind('', 'n4'));
  document.getElementById('headerLogo').addEventListener('click', closeNavigation.bind('', 'nn'));

  // 点击放大镜时显示大幅作品展示页
  document.getElementById('zoom').addEventListener('click', openLargeWorks.bind(''));

  // 点击大幅作品页的缩略图，让相应的作品居中
  var smallWorksImages = document.querySelectorAll('.smallWorksImages');
  for(const smallWorksImage of smallWorksImages) {
    smallWorksImage.addEventListener('click', scrollToImage.bind('', smallWorksImage));
  }

  // 播放大幅作品页内的视频时暂停音乐
  var largeVideos = document.getElementsByClassName('pauseMusic');
  for(var i = 0; i < largeVideos.length; i++) {
    largeVideos[i].addEventListener('play', musicController.bind('', 'pause'));
  }

  // 关闭大幅作品展示页
  document.getElementById('closeLargeImage').addEventListener('click', closeLargeWorks.bind(''));
}



//-- 重置窗口大小事件 --//
function resize() {
  width = window.innerWidth;
  height = window.innerHeight;

  if(width > 1100) {
    // 导航栏1打开时，使导航栏2处于打开状态
    if(settings.navigationOpened) {
      // 记录导航栏2打开状态
      settings.categoryOpened = true;
      document.getElementById('navigationContent2').classList.remove('n2Out');
      document.getElementById('navigationContent2').classList.remove('n2InAnimation');
      document.getElementById('navigationContent2').classList.add('n2In');

      // 重新播放导航栏2的分割线动画
      document.getElementById('categoryLine1').classList.remove('categoryLine1');
      document.getElementById('categoryLine2').classList.remove('categoryLine2');
      document.getElementById('categoryLine3').classList.remove('categoryLine3');
      document.getElementById('categoryLine4').classList.remove('categoryLine4');
      var n2LineTimeOut = setTimeout(() => {
        document.getElementById('categoryLine1').classList.add('categoryLine1');
        document.getElementById('categoryLine2').classList.add('categoryLine2');
        document.getElementById('categoryLine3').classList.add('categoryLine3');
        document.getElementById('categoryLine4').classList.add('categoryLine4');
        clearTimeout(n2LineTimeOut);
      }, 50);

      // 如果导航栏3未打开时，显示导航栏2的关闭按钮，反之不显示导航栏2的关闭按钮
      if(settings.worksListOpened) {
        dom.c2.classList.remove('c2In');
        dom.c2.classList.add('c2Out');

        // 导航栏3分割线动画
        var worksListLines = document.querySelectorAll('#' + settings.category + ' .worksListLine');
        for(var i = 0; i < worksListLines.length; i++) {
          worksListLines[i].classList.remove('worksListLineAnimation' + (i + 1));
        }
        var n3LineTimeOut = setTimeout(() => {
          for(var i = 0; i < worksListLines.length; i++) {
            worksListLines[i].classList.add('worksListLineAnimation' + (i + 1));
          }
        }, 150);

        // 如果导航栏4处于打开状态时
        if(settings.worksDetailOpened) {
          // 把导航栏3的关闭按钮切换到导航栏4
          dom.c3.classList.remove('c3In');
          dom.c3.classList.add('c3Out');
        }else{
          dom.c3.classList.remove('c3Out');
          dom.c3.classList.add('c3In');
        }
      }else{
        dom.c2.classList.remove('c2Out');
        dom.c2.classList.add('c2In');
      }
    }

    // 导航栏4的媒体尺寸根据其容器比例调整大小
    if(document.getElementById('worksMediaContainer').children[0]) {
      var mediaRatio = document.getElementById('worksMediaContainer').children[0].naturalWidth / document.getElementById('worksMediaContainer').children[0].naturalHeight;
      var containerRatio = document.getElementById('worksMediaContainer').offsetWidth / document.getElementById('worksMediaContainer').offsetHeight;
      if(containerRatio >= mediaRatio) {
        document.getElementById('worksMediaContainer').children[0].classList.remove('widthFirst');
        document.getElementById('worksMediaContainer').children[0].classList.add('heightFirst');
      }else{
        document.getElementById('worksMediaContainer').children[0].classList.remove('heightFirst');
        document.getElementById('worksMediaContainer').children[0].classList.add('widthFirst');
      }
    }

    // 在高分辨率下，大幅作品页打开时，页头回到原始层级
    if(settings.largeWorksOpened) {
      document.getElementById('header').style.zIndex = 2;
      document.getElementById('header').classList.remove('headerwhite');
      document.getElementById('header').classList.remove('headerBlack');
      document.getElementById('headerLogo').setAttribute('src', './src/images/logoTextWhite.svg');
      document.getElementById('navigationButtonLine1').style.backgroundColor = 'white';
      document.getElementById('navigationButtonLine2').style.backgroundColor = 'white';
      document.getElementById('navigationButtonLine3').style.backgroundColor = 'white';
    }

    // // 不显示滚动条
    // dom.lwi.addEventListener("wheel", (event) => {
    //   event.preventDefault();
    // }, {passive: false});
    // 大幅作品页详细描述的宽度设置成60vw
    document.getElementById('largeWorksText').classList.remove('largeWorksText1100');
    document.getElementById('largeWorksText').classList.add('largeWorksText');
    // 如果当前大幅作品页详细描述离左边大于60时，减到60vw
    if(settings.moveToLeft > 60) {
      settings.moveToLeft = 60;
      gsap.to(document.getElementById('largeWorksText'), {
        left: 100 - settings.moveToLeft + 'vw',
        duration: 0.15
      });
    }

    // 大幅作品页缩放时，圆点重新计算位置
    moveSideAndPoint();
  }else if(width <= 1100) {
    if(settings.categoryOpened) {
      dom.c2.classList.remove('c2Out');
      dom.c2.classList.add('c2In');
    }
    if(settings.worksListOpened) {
      dom.c3.classList.remove('c3Out');
      dom.c3.classList.add('c3In');
    }

    // 在低分辨率下，大幅作品页打开时，页头调到最高层级
    if(settings.largeWorksOpened) {
      document.getElementById('header').style.zIndex = 8;
      document.getElementById('header').classList.add('headerWhite');
      document.getElementById('headerLogo').setAttribute('src', './src/images/logoTextBlack.svg');
      document.getElementById('navigationButtonLine1').style.backgroundColor = 'black';
      document.getElementById('navigationButtonLine2').style.backgroundColor = 'black';
      document.getElementById('navigationButtonLine3').style.backgroundColor = 'black';
    }
    // // 大幅作品页强制显示滚动条
    // dom.lwi.classList.remove('scrollHidden');
    // dom.lwi.classList.remove('scrollDisplay');
    // // 显示滚动条
    // dom.lwi.addEventListener("wheel", (event) => {
      
    // }, {passive: true});
    // 大幅作品页详细描述的宽度设置成100vw
    document.getElementById('largeWorksText').classList.remove('largeWorksText');
    document.getElementById('largeWorksText').classList.add('largeWorksText1100');

    // 重置到屏幕之外
    if(settings.moveToLeft > 0) {
      settings.moveToLeft = 0;
      // 屏幕宽度小于等于1100时，作品会全屏宽
      gsap.to(dom.lwi, {
        width: 100 - settings.moveToLeft + 'vw',
        duration: 0.25,
        ease: 'power0'
      });
      gsap.to(document.getElementById('largeWorksText'), {
        left: '100vw',
        duration: 0.15
      });
    }
    settings.largeWorksDetailsOpened = false;

    if(width <= 800 && settings.aboutOpened) {
      document.getElementById('header').classList.add('headerBlack');
    }else{
      document.getElementById('header').classList.remove('headerBlack');
    }
  }
}



//-- 音乐控制器 --// 
function musicController(order) {
  document.getElementById('indexAudio').volume = 0.5;
  if(order == 'play') {
    document.getElementById('indexAudio').play();
    settings.musicPlayed = true;
  }else if(order == 'pause') {
    document.getElementById('indexAudio').pause();
    settings.musicPlayed = false;
    document.getElementById('musicLine1').classList.add('musicLine1');
    document.getElementById('musicLine2').classList.add('musicLine2');
    document.getElementById('musicLine3').classList.add('musicLine3');
    document.getElementById('musicLine4').classList.add('musicLine4');
    document.getElementById('musicLine5').classList.add('musicLine5');
  }else{
    if(settings.musicPlayed == false) {
      document.getElementById('indexAudio').play();
      settings.musicPlayed = true;
    }else{
      document.getElementById('indexAudio').pause();
      settings.musicPlayed = false;
      document.getElementById('musicLine1').classList.add('musicLine1');
      document.getElementById('musicLine2').classList.add('musicLine2');
      document.getElementById('musicLine3').classList.add('musicLine3');
      document.getElementById('musicLine4').classList.add('musicLine4');
      document.getElementById('musicLine5').classList.add('musicLine5');
    }
  }
}


//-- 点击页脚邮箱时复制邮箱地址到剪切板 --//
function footerEmail() {
  var email = document.querySelectorAll('#footerEmail p');
  for(var i = 0; i < email.length; i++) {
    email[i].classList.add('footerEmailAnimation');
  }
  var emailTimeOut = setTimeout(() => {
    for(var i = 0; i < email.length; i++) {
      email[i].classList.remove('footerEmailAnimation');
    }
    clearTimeout(emailTimeOut);
  }, 5000);
  navigator.clipboard.writeText('original@lorearth.com');
}



//-- 导航按钮交互 --//
function openNavigation() {
  if(!settings.aboutOpened && !settings.largeWorksOpened) {
    // 打开灰幕遮挡背景
    document.getElementById('grey').classList.remove('greyOut');
    document.getElementById('grey').classList.add('greyIn');

    // 记录导航栏1打开状态
    settings.navigationOpened = true;
    dom.n1.classList.add('n1InAnimation');
    var n1TimeOut = setTimeout(() => {
      dom.n1.classList.remove('n1Out');
      dom.n1.classList.remove('n1InAnimation');
      dom.n1.classList.add('n1In');
      clearTimeout(n1TimeOut);
    }, 700);

    // 如果屏幕宽度大于1100像素
    if(width > 1100) {
      // 记录导航栏2打开状态
      settings.categoryOpened = true;
      dom.n2.classList.add('n2InAnimation');
      var n2TimeOut = setTimeout(() => {
        dom.n2.classList.remove('n2Out');
        dom.n2.classList.remove('n2InAnimation');
        dom.n2.classList.add('n2In');
        clearTimeout(n2TimeOut);
      }, 1050);

      // 导航栏2分割线动画
      var n2LineTimeOut = setTimeout(() => {
        document.getElementById('categoryLine1').classList.add('categoryLine1');
        document.getElementById('categoryLine2').classList.add('categoryLine2');
        document.getElementById('categoryLine3').classList.add('categoryLine3');
        document.getElementById('categoryLine4').classList.add('categoryLine4');
        clearTimeout(n2LineTimeOut);
      }, 700);
    }
  }else if(settings.largeWorksOpened) {
    // 记录大幅作品页打开状态
    settings.largeWorksOpened = false;
    // 关闭大幅作品页面
    dom.lw.classList.remove('isDisplay');
    dom.lw.classList.add('noDisplay');
    // 暂停大幅作品页内所有视频

    // 回复页头层级和样式
    document.getElementById('header').style.zIndex = 2;
    document.getElementById('header').classList.remove('headerWhite');
    document.getElementById('header').classList.remove('headerBlack');
    document.getElementById('headerLogo').setAttribute('src', './src/images/logoTextWhite.svg');
    document.getElementById('navigationButtonLine1').style.backgroundColor = 'white';
    document.getElementById('navigationButtonLine2').style.backgroundColor = 'white';
    document.getElementById('navigationButtonLine3').style.backgroundColor = 'white';

    var videos = document.querySelector('#largeWorksImages video');
    for(var i = 1; i < videos.length; i++) {
      videos[i].pause();
    }
  }else if(settings.aboutOpened) {
    // 记录关闭about页面状态
    settings.aboutOpened = false;
    // 使用关闭about页面的动画
    document.getElementById('about').classList.add('noDisplayAnimation');
    // 0.5s后完成切换样式
    var aboutOutTimeOut = setTimeout(() => {
      document.getElementById('about').classList.remove('isDisplay');
      document.getElementById('about').classList.remove('noDisplayAnimation');
      document.getElementById('about').classList.add('noDisplay');

      clearTimeout(aboutOutTimeOut);
    }, 500);

    // 使页头处于原来层
    document.getElementById('header').style.zIndex = 2;
    document.getElementById('header').classList.remove('headerWhite');
    document.getElementById('header').classList.remove('headerBlack');
    document.getElementById('headerLogo').setAttribute('src', './src/images/logoTextWhite.svg');
    document.getElementById('navigationButtonLine1').style.backgroundColor = 'white';
    document.getElementById('navigationButtonLine2').style.backgroundColor = 'white';
    document.getElementById('navigationButtonLine3').style.backgroundColor = 'white';
    // 使页头关闭按钮切换成导航按钮
    document.getElementById('navigationButtonLine').classList.remove('noDisplay');
    document.getElementById('navigationButtonLine').classList.add('isDisplay');
    document.getElementById('navigationButtonX').classList.remove('isDisplay');
    document.getElementById('navigationButtonX').classList.add('noDisplay');
    document.getElementById('headerLine').classList.remove('noDisplay');
    document.getElementById('headerLine').classList.add('isDisplay');
  }
}



//-- 打开导航栏2 --//
function openCategory() {
  if(width <= 1100) {
    // 记录导航栏2打开状态
    settings.categoryOpened = true;
    dom.n2.classList.add('n2InAnimation');
    var n2TimeOut = setTimeout(() => {
      dom.n2.classList.remove('n2Out');
      dom.n2.classList.remove('n2InAnimation');
      dom.n2.classList.add('n2In');
      clearTimeout(n2TimeOut);
    }, 700);
    
    // 导航栏2分割线动画
    var n2LineTimeOut = setTimeout(() => {
      document.getElementById('categoryLine1').classList.add('categoryLine1');
      document.getElementById('categoryLine2').classList.add('categoryLine2');
      document.getElementById('categoryLine3').classList.add('categoryLine3');
      document.getElementById('categoryLine4').classList.add('categoryLine4');
      clearTimeout(n2LineTimeOut);
    }, 700);
  }
}



//-- 打开导航栏3 --//
function openWorksList(event, categoryName) {
  settings.category = categoryName;
  // 打开导航栏3
  if(width > 1100 && event == 'enter') {
    n3Open();
  }else if(width <= 1100 && event == 'click') {
    n3Open();
  }
  function n3Open() {
    if(!settings.worksListOpened) {
      // 记录导航栏3打开状态
      settings.worksListOpened = true;
      // 播放导航栏3入场动画
      dom.n3.classList.add('n3InAnimation');
      var n3TimeOut = setTimeout(() => {
        dom.n3.classList.remove('n3Out');
        dom.n3.classList.remove('n3InAnimation');
        dom.n3.classList.add('n3In');
        clearTimeout(n3TimeOut);
      }, 700);
    }
  }

  // 把导航栏2的关闭按钮切换到导航栏3
  if(width > 1100) {
    dom.c2.classList.remove('c2In');
    dom.c2.classList.add('c2Out');
  }
  dom.c3.classList.remove('c3Out');
  dom.c3.classList.add('c3In');

  // 导航栏3的内容切换成所选的类别
  if(categoryName == 'vi') {
    document.getElementById('vi').classList.remove('noDisplay');
    document.getElementById('vi').classList.add('isDisplay');
    document.getElementById('web').classList.remove('isDisplay');
    document.getElementById('web').classList.add('noDisplay');
    document.getElementById('graphics').classList.remove('isDisplay');
    document.getElementById('graphics').classList.add('noDisplay');
  }else if(categoryName == 'web') {
    document.getElementById('vi').classList.remove('isDisplay');
    document.getElementById('vi').classList.add('noDisplay');
    document.getElementById('web').classList.remove('noDisplay');
    document.getElementById('web').classList.add('isDisplay');
    document.getElementById('graphics').classList.remove('isDisplay');
    document.getElementById('graphics').classList.add('noDisplay');
  }else if(categoryName == 'graphics') {
    document.getElementById('vi').classList.remove('isDisplay');
    document.getElementById('vi').classList.add('noDisplay');
    document.getElementById('web').classList.remove('isDisplay');
    document.getElementById('web').classList.add('noDisplay');
    document.getElementById('graphics').classList.remove('noDisplay');
    document.getElementById('graphics').classList.add('isDisplay');
  }

  // 导航栏3分割线动画
  var worksListLines = document.querySelectorAll('#' + categoryName + ' .worksListLine');
  for(var i = 0; i < worksListLines.length; i++) {
    worksListLines[i].classList.remove('worksListLineAnimation' + (i + 1));
  }
  var n3LineTimeOut = setTimeout(() => {
    for(var i = 0; i < worksListLines.length; i++) {
      worksListLines[i].classList.add('worksListLineAnimation' + (i + 1));
    }
  }, 50);
}



//-- 打开导航栏4 --//
function openWorksDetail(event, htmlTag, worksName, title, author, client, date) {
  settings.worksName = worksName;

  // 打开导航栏4
  if(!settings.worksDetailOpened) {
    // 记录导航栏4打开状态
    settings.worksDetailOpened = true;
    // 播放导航栏4入场动画
    dom.n4.classList.add('n4InAnimation');
    var n4InTimeOut = setTimeout(() => {
      dom.n4.classList.remove('n4Out');
      dom.n4.classList.remove('n4InAnimation');
      dom.n4.classList.add('n4In');
    }, 700);
  }

  // 把导航栏3的关闭按钮切换到导航栏4
  if(width > 1100) {
    dom.c3.classList.remove('c3In');
    dom.c3.classList.add('c3Out');
  }else if(width <= 1100 && event == 'click') {
    openLargeWorks();
  }

  // 替换导航栏4中的媒体容器内的媒体
  document.getElementById('worksMediaContainer').innerHTML = htmlTag;
  document.getElementById('worksTitle').innerHTML = title;
  document.getElementById('worksDetailsDirector').children[1].innerHTML = author;
  document.getElementById('worksDetailsClient').children[1].innerHTML = client;
  document.getElementById('worksDetailsTime').children[1].innerHTML = date;

  // 导航栏4的媒体尺寸根据其容器比例调整大小
  if(document.getElementById('worksMediaContainer').children[0]) {
    var mediaRatio = document.getElementById('worksMediaContainer').children[0].naturalWidth / document.getElementById('worksMediaContainer').children[0].naturalHeight;
    var containerRatio = document.getElementById('worksMediaContainer').offsetWidth / document.getElementById('worksMediaContainer').offsetHeight;
    if(containerRatio >= mediaRatio) {
      document.getElementById('worksMediaContainer').children[0].classList.remove('widthFirst');
      document.getElementById('worksMediaContainer').children[0].classList.add('heightFirst');
    }else{
      document.getElementById('worksMediaContainer').children[0].classList.remove('heightFirst');
      document.getElementById('worksMediaContainer').children[0].classList.add('widthFirst');
    }
  }
}



//-- 关闭导航 --//
function closeNavigation(nN) {
  if(width > 1100) {
    // 关闭灰幕
    document.getElementById('grey').classList.remove('greyIn');
    document.getElementById('grey').classList.add('greyOut');

    // 使用关闭导航栏1、导航栏2、导航栏3、导航栏4和about页面的动画
    if(settings.navigationOpened) {
      dom.n1.classList.remove('n1In');
      dom.n1.classList.add('noDisplayAnimation');
    }
    if(settings.navigationOpened) {
      dom.n2.classList.remove('n2In');
      dom.n2.classList.add('n2OutAnimation');
    }
    if(settings.worksListOpened) {
      dom.n3.classList.remove('n3In');
      dom.n3.classList.add('n3OutAnimation');
    }
    if(settings.worksDetailOpened) {
      dom.n4.classList.remove('n4In');
      dom.n4.classList.add('n4OutAnimation');
    }

    // 记录关闭所有页面的设置
    settings.navigationOpened = false;
    settings.categoryOpened = false;
    settings.worksListOpened = false;
    settings.worksDetailOpened = false;

    // 0.5s后完成切换样式
    var nAllOutTimeOut = setTimeout(() => {
      // 切换导航栏1的显示样式
      dom.n1.classList.remove('noDisplayAnimation');
      dom.n1.classList.add('n1Out');
      // 切换导航栏2的显示样式
      dom.n2.classList.remove('n2OutAnimation');
      dom.n2.classList.add('n2Out');
      dom.c2.classList.remove('c2Out');
      dom.c2.classList.add('c2In');
      // 切换导航栏3的显示样式
      dom.n3.classList.remove('n3OutAnimation');
      dom.n3.classList.add('n3Out');
      // 切换导航栏4的显示样式
      dom.n4.classList.remove('n4OutAnimation');
      dom.n4.classList.add('n4Out');

      clearTimeout(nAllOutTimeOut);
    }, 500);
  }else if(width <= 1100) {
    if(nN == 'n1') {
      // 记录关闭导航栏1的设置
      settings.navigationOpened = false;
      // 关闭灰幕
      document.getElementById('grey').classList.remove('greyIn');
      document.getElementById('grey').classList.add('greyOut');
      // 使用关闭导航栏1的动画
      dom.n1.classList.add('noDisplayAnimation');
      // 0.5s后完成切换样式
      var nAllOutTimeOut = setTimeout(() => {
        dom.n1.classList.remove('n1In');
        dom.n1.classList.remove('noDisplayAnimation');
        dom.n1.classList.add('n1Out');
        clearTimeout(nAllOutTimeOut);
      }, 500);
    }else if(nN == 'n2') {
      // 记录关闭导航栏2的设置
      settings.categoryOpened = false;
      // 使用关闭导航栏2的动画
      dom.n2.classList.add('noDisplayAnimation');
      // 0.5s后完成切换样式
      var nAllOutTimeOut = setTimeout(() => {
        dom.n2.classList.remove('n2In');
        dom.n2.classList.remove('noDisplayAnimation');
        dom.n2.classList.add('n2Out');
        clearTimeout(nAllOutTimeOut);
      }, 500);
    }else if(nN == 'n3') {
      // 记录关闭导航栏3和导航栏4的设置
      settings.worksListOpened = false;
      settings.worksDetailOpened = false;
      // 使用关闭导航栏3和导航栏4的动画
      dom.n3.classList.add('noDisplayAnimation');
      dom.n4.classList.add('noDisplayAnimation');
      // 0.5s后完成切换样式
      var nAllOutTimeOut = setTimeout(() => {
        dom.n3.classList.remove('n3In');
        dom.n3.classList.remove('noDisplayAnimation');
        dom.n3.classList.add('n3Out');

        dom.n4.classList.remove('n4In');
        dom.n4.classList.remove('noDisplayAnimation');
        dom.n4.classList.add('n4Out');

        clearTimeout(nAllOutTimeOut);
      }, 500);
    }else if(nN == 'nn') {
      // 关闭灰幕
      document.getElementById('grey').classList.remove('greyIn');
      document.getElementById('grey').classList.add('greyOut');

      // 关闭导航栏1、导航栏2、导航栏3、导航栏4、大幅作品页和about页面
      dom.n1.classList.remove('n1In');
      dom.n1.classList.add('n1Out');
      dom.n2.classList.remove('n2In');
      dom.n2.classList.add('n2Out');
      dom.n3.classList.remove('n3In');
      dom.n3.classList.add('n3Out');
      dom.n4.classList.remove('n4In');
      dom.n4.classList.add('n4Out');
      dom.lw.classList.remove('isDisplay');
      dom.lw.classList.add('noDisplay');
      document.getElementById('about').classList.remove('isDisplay');
      document.getElementById('about').classList.add('noDisplay');

      // 使页头处于原来层
      document.getElementById('header').style.zIndex = 2;
      document.getElementById('header').classList.remove('headerWhite');
      document.getElementById('header').classList.remove('headerBlack');
      document.getElementById('headerLogo').setAttribute('src', './src/images/logoTextWhite.svg');
      document.getElementById('navigationButtonLine1').style.backgroundColor = 'white';
      document.getElementById('navigationButtonLine2').style.backgroundColor = 'white';
      document.getElementById('navigationButtonLine3').style.backgroundColor = 'white'; 

      // 记录关闭所有页面的设置
      settings.navigationOpened = false;
      settings.categoryOpened = false;
      settings.worksListOpened = false;
      settings.worksDetailOpened = false;
      settings.largeWorksOpened = false;
      settings.aboutOpened = false;
    }
  }
}



//-- 打开大幅作品页 --//
function openLargeWorks() {
  // 记录大幅作品页打开状态
  settings.largeWorksOpened = true;
  // 重置大幅作品页的滚动起点
  gsap.to(dom.lwi, {
    scrollTo: 0,
    duration: 0.25
  });
  moveSideAndPoint();
  // 调整页头层级
  if(width <= 1100) {
    document.getElementById('header').style.zIndex = 8;
    document.getElementById('header').classList.remove('headerBlack');
    document.getElementById('header').classList.add('headerWhite');
    document.getElementById('headerLogo').setAttribute('src', './src/images/logoTextBlack.svg');
    document.getElementById('navigationButtonLine1').style.backgroundColor = 'black';
    document.getElementById('navigationButtonLine2').style.backgroundColor = 'black';
    document.getElementById('navigationButtonLine3').style.backgroundColor = 'black';
  }

  // 显示大幅作品页
  dom.lw.classList.remove('noDisplay');
  dom.lw.classList.add('isDisplay');

  // 使大幅作品页内所有大图和元素不可见
  for(var i = 0; i < document.getElementById('largeWorksImages').children.length; i++) {
    document.getElementById('largeWorksImages').children[i].classList.remove('flexDisplay');
    document.getElementById('largeWorksImages').children[i].classList.add('noDisplay');
  }
  // 使大幅作品页内所有大图和元素不可见
  for(var i = 0; i < document.getElementById('smallWorksImages').children.length; i++) {
    document.getElementById('smallWorksImages').children[i].classList.remove('isDisplay');
    document.getElementById('smallWorksImages').children[i].classList.add('noDisplay');
  }
  
  // 使大幅作品也内指定大图和缩略图元素可见
  document.getElementsByClassName(settings.worksName)[0].classList.remove('noDisplay');
  document.getElementsByClassName(settings.worksName)[0].classList.add('flexDisplay');
  document.getElementsByClassName(settings.worksName)[1].classList.remove('noDisplay');
  document.getElementsByClassName(settings.worksName)[1].classList.add('isDisplay');
  

  // 详细描述部分内容修改为当前作品的详细描述
  document.querySelector('#largeWorksText h1').innerHTML = document.getElementById('worksTitle').innerHTML;
  document.querySelector('#largeWorksText #largeWorksText_en').innerHTML = document.querySelector('.' + settings.worksName + ' .largeWorksText_en').innerHTML;
  document.querySelector('#largeWorksText #largeWorksText_zh').innerHTML = document.querySelector('.' + settings.worksName + ' .largeWorksText_zh').innerHTML;
}



//-- 关闭大幅作品页 --//
function closeLargeWorks() {
  // 记录大幅作品页打开状态
  settings.largeWorksOpened = false;
  // 打开大幅作品页面
  dom.lw.classList.remove('isDisplay');
  dom.lw.classList.add('noDisplay');
  // 回复页头层级和样式
  document.getElementById('header').style.zIndex = 2;
  document.getElementById('header').classList.remove('headerwhite');
  document.getElementById('header').classList.remove('headerBlack');
  document.getElementById('headerLogo').setAttribute('src', './src/images/logoTextWhite.svg');
  document.getElementById('navigationButtonLine1').style.backgroundColor = 'white';
  document.getElementById('navigationButtonLine2').style.backgroundColor = 'white';
  document.getElementById('navigationButtonLine3').style.backgroundColor = 'white';

  var videos = document.querySelector('#largeWorksImages video');
  for(var i = 1; i < videos.length; i++) {
    videos[i].pause();
    console.log(videos[i]);
  }
}



//-- 滚动侧边栏和黑点 --//
function moveSideAndPoint() {
  // 侧边缩略图跟随页面的滚动而向上移动
  gsap.to(document.getElementById('sideList'), {
    // 半屏- 滚动的位置/作品总高度=比例 *高度(得到实际上离顶部的距离) *小数(按这个比例移动)
    top: (height * 0.5) - dom.lwi.scrollTop * 0.035 + 'px',
    duration: 0.5
  });

  // 通过作品移动到屏幕中的位置判断当前的作品序号
  for(var i = 0; i <= settings.worksImageTotal - 1; i++) {
    if(dom.lwi.scrollTop >= i * height - height * 0.5) {
      settings.worksImageNow = i;
    }
  }
  
  // 当前作品的高度
  var thisDomHeight;
  if(document.querySelector('#smallWorksImages .' + settings.worksName)) {
    thisDomHeight = document.querySelector('#smallWorksImages .' + settings.worksName).children[0].getBoundingClientRect().height;
  }
  
  // 在当前作品之前的所有作品高度
  var beforeDomHeight = 0;
  for(var i = 1; i <= settings.worksImageNow; i++) {
    if(document.querySelector('#smallWorksImages .' + settings.worksName).children[i]) {
      beforeDomHeight += document.querySelector('#smallWorksImages .' + settings.worksName).children[i].getBoundingClientRect().height;
    }
  }

  // 计算圆点当前应该所处的滚动高度
  var pointTimeOut = setTimeout(() => {
    var temlValue_pointShift = beforeDomHeight + thisDomHeight * 0.5 + (dom.lwi.scrollTop - settings.worksImageNow * height) / height * thisDomHeight - dom.point.getBoundingClientRect().height * 0.5 + 'px';
    gsap.to(dom.point, {
      top: temlValue_pointShift,
      duration: 0.5
    });
    clearTimeout(pointTimeOut);
  }, 100);
}



//-- 作品详细描述--鼠标点击侧边栏缩略图时滚动到相应作品 --//
function scrollToImage(smallWorksImage) {
  settings.worksImageNow = smallWorksImage.children[0].getAttribute('id');
  // 大幅作品滚动到点击的图片位置
  var tempValue_nowHeight = settings.worksImageNow * height;
  gsap.to(dom.lwi, {
    scrollTo: tempValue_nowHeight,
    duration: 0.5
  });

  // 侧边缩略图跟随页面的滚动而向上移动
  gsap.to(document.getElementById('sideList'), {
    // 半屏- 滚动的位置/作品总高度=比例 *高度(得到实际上离顶部的距离) *小数(按这个比例移动)
    top: (height * 0.5) - dom.lwi.scrollTop * 0.035 + 'px',
    duration: 0.5
  });

  // 根据每一幅作品的高度计算圆点应该移动的距离
  var thisDomHeight = document.querySelector('#smallWorksImages .' + settings.worksName).children[settings.worksImageNow].getBoundingClientRect().height;
  var beforeDomHeight = 0;
  for(var i = 0; i <= settings.worksImageNow - 1; i++) {
    beforeDomHeight += document.querySelector('#smallWorksImages .' + settings.worksName).children[i].getBoundingClientRect().height;
  }

  // 计算圆点当前应该所处的滚动高度
  var temlValue_pointShift = beforeDomHeight + thisDomHeight * 0.5 - dom.point.getBoundingClientRect().height * 0.5 + 'px';
  gsap.to(dom.point, {
    top: temlValue_pointShift,
    duration: 0.5
  });
}



//-- 打开关于我们 --//
function openAbout() {
  // 使页头处于最上层
  document.getElementById('header').style.zIndex = 4;
  if(width <= 800) {
    document.getElementById('header').classList.add('headerBlack');
  }

  // 使页头导航按钮切换成关闭按钮
  document.getElementById('navigationButtonLine').classList.remove('isDisplay');
  document.getElementById('navigationButtonLine').classList.add('noDisplay');
  document.getElementById('navigationButtonX').classList.remove('noDisplay');
  document.getElementById('navigationButtonX').classList.add('isDisplay');
  document.getElementById('headerLine').classList.remove('isDisplay');
  document.getElementById('headerLine').classList.add('noDisplay');

  // 显示about页面
  document.getElementById('about').classList.add('isDisplayAnimation');
  document.getElementById('about').classList.remove('noDisplay');
  var aboutInTimeOut = setTimeout(() => {
    document.getElementById('about').classList.remove('isDisplayAnimation');
    document.getElementById('about').classList.add('isDisplay');

    clearTimeout(aboutInTimeOut);
  }, 500);
  
  // 关闭导航栏
  closeNavigation('n1');
  // 打开about页面
  settings.aboutOpened = true;
}



//-- 测试模块 --//
/*function setTest() {
  gui = new dat.GUI();
}*/



/*// 目前问题出在audioCtx.createMediaElementSource(audioElement)这里，没有获取到音源,除非在播放音乐的时候创建音源
var audioElement = document.getElementById('indexAudio');
// 设置允许跨域,audioContext连接对跨域有限制
// audioElement.crossOrigin = "anonymous"
// 创建音频语境
var audioCtx = new (window.AudioContext || window.webkitAudioContext)();
// 创建音频频谱
var analyser = audioCtx.createAnalyser();
audioElement.addEventListener('play', () => {
  // 得到音源（目前问题出在这里，没有获取到音源除非在播放音乐的时候创建音源）
  var source = audioCtx.createMediaElementSource(audioElement);
  // var source = new MediaElementAudioSourceNode(audioCtx, {mediaElement: audioElement,});
  // 将音源连接到频谱
  source.connect(analyser);
  // 频谱连接到扬声器
  analyser.connect(audioCtx.destination);
  // 设置获取的频谱范围
  analyser.fftSize = 32;
  // 获取频谱长度
  var bufferLength = analyser.frequencyBinCount;
  // 获取频谱数组
  var dataArray = new Uint8Array(bufferLength);
  analyser.getByteFrequencyData(dataArray);
  function draw() {
    requestAnimationFrame(draw);
    analyser.getByteFrequencyData(dataArray);
    // console.log(dataArray);
    if(settings.musicPlayed == true) {
      document.getElementById('musicLine1').classList.remove('musicLine1');
      document.getElementById('musicLine2').classList.remove('musicLine2');
      document.getElementById('musicLine3').classList.remove('musicLine3');
      document.getElementById('musicLine4').classList.remove('musicLine4');
      document.getElementById('musicLine5').classList.remove('musicLine5');

      document.getElementById('musicLine1').style.height = dataArray[4] / 2 + '%';
      document.getElementById('musicLine2').style.height = dataArray[3] / 2 + '%';
      document.getElementById('musicLine3').style.height = dataArray[2] / 2 + '%';
      document.getElementById('musicLine4').style.height = dataArray[1] / 2 + '%';
      document.getElementById('musicLine5').style.height = dataArray[0] / 2 + '%';
    }
  }
  draw();
});*/

/*navigator.serviceWorker.getRegistrations().then(function(registrations) {
  for(var registration of registrations) {
    registration.unregister();
  }
})

const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(
        'sw.js',
        {
          scope: './',
        }
      );
      if (registration.installing) {
        console.log('Service worker installing');
      } else if (registration.waiting) {
        console.log('Service worker installed');
      } else if (registration.active) {
        console.log('Service worker active');
      }
    } catch (error) {
      console.error('Registration failed with ' + error);
    }
  }
};

registerServiceWorker();*/

